import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutSettingsApi} from '../apis/CheckoutSettingsApi';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {CheckoutOrigin} from '../utils/bi.util';

export class CheckoutSettingsService {
  private readonly controllerConfig: IWidgetControllerConfig;
  private readonly siteStore: SiteStore;
  private readonly checkoutSettingsApi: CheckoutSettingsApi;
  public checkoutSettings!: CheckoutSettingsModel;
  public showCouponSP!: boolean;

  constructor({controllerConfig, siteStore}: {controllerConfig: IWidgetControllerConfig; siteStore: SiteStore}) {
    this.controllerConfig = controllerConfig;
    this.siteStore = siteStore;
    this.checkoutSettingsApi = new CheckoutSettingsApi({siteStore, origin: CheckoutOrigin.checkout});
  }

  public async fetchSiteSettings(): Promise<void> {
    this.checkoutSettings = new CheckoutSettingsModel(
      await this.checkoutSettingsApi.getCheckoutSettings(),
      this.siteStore.taxName
    );
  }

  public async init(): Promise<void> {
    await this.fetchSiteSettings();
    this.showCouponSP = this.controllerConfig.config.style.styleParams.booleans.checkout_showCoupon;
  }
}
