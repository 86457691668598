import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {isValidHttpUrl} from '../utils/isValidHttpUrl';
import {ILink, MY_WALLET_APP_DEFINITION_ID, PageMap} from '@wix/wixstores-client-core';
import {IWidgetControllerConfig} from '@wix/native-components-infra';
import {addWhiteListParamsToUrl} from '../utils/whiteListParams';
import {SPECS} from '../../components/Checkout/constants';
import {getHeadlessUrl, NavigationType} from '@wix/wix-to-headless-redirect-client';

export interface CheckoutAppSectionParams {
  checkoutId?: string;
  continueShoppingUrl?: string;
  cashierPaymentId?: string;
  isPickupFlow?: boolean;
  origin?: string;
  originType?: string;
}

export class NavigationService {
  private readonly appSectionParams: CheckoutAppSectionParams;
  public readonly currency?: string;
  private readonly siteStore: SiteStore;
  private readonly controllerConfig: IWidgetControllerConfig;
  public isLoaded: boolean;
  public isMyWalletAppInstalled: boolean = false;
  private continueShoppingLink!: ILink;
  private fetchedEditCartUrl!: string;

  constructor({siteStore, controllerConfig}: {siteStore: SiteStore; controllerConfig: IWidgetControllerConfig}) {
    this.isLoaded = false;
    this.siteStore = siteStore;
    this.controllerConfig = controllerConfig;
    this.appSectionParams = this.getAppSectionParams();
    this.currency = this.getCurrency();
  }

  public async load() {
    this.continueShoppingLink = await this.fetchContinueShoppingLink();
    this.fetchedEditCartUrl = await this.fetchEditCartUrl();
    this.isMyWalletAppInstalled = await this.fetchIsMyWalledAppInstalled();
    this.isLoaded = true;
  }

  private fetchIsMyWalledAppInstalled() {
    return this.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      sectionId: MY_WALLET_APP_DEFINITION_ID,
      appDefinitionId: 'my_wallet',
    });
  }

  public navigateToOldCheckout() {
    const url = new URL(this.siteStore.location.url);
    url.searchParams.delete('checkoutOOI');
    this.siteStore.navigateToLink(this.getExternalLink(url.toString()));
  }

  public reload() {
    const url = new URL(this.siteStore.location.url);
    this.siteStore.navigateToLink(this.getInternalLink(url.toString()));
  }

  public getCheckoutCancelUrl() {
    const url = new URL(this.siteStore.location.url);
    url.searchParams.set('redirect', 'cancel');
    addWhiteListParamsToUrl(url, this.siteStore.location.url);
    return url.toString();
  }

  public getCheckoutErrorUrl() {
    const url = new URL(this.siteStore.location.url);
    url.searchParams.set('redirect', 'error');
    addWhiteListParamsToUrl(url, this.siteStore.location.url);
    return url.toString();
  }

  public async getThankYouPageUrlForPlaceOrder() {
    const {url} = await this.siteStore.getSectionUrl(PageMap.THANKYOU);
    // istanbul ignore if
    if (!url) {
      return;
    }
    const orderIdPlaceholder = 'orderIdPlaceholder';
    const thankYouPageUrl = new URL(`${url}/${orderIdPlaceholder}`);
    const appSectionParams = JSON.stringify({
      objectType: 'order',
      origin: 'checkout',
      continueShoppingUrl: this.continueShoppingLink.url,
    });
    thankYouPageUrl.searchParams.set('appSectionParams', appSectionParams);
    addWhiteListParamsToUrl(thankYouPageUrl, this.siteStore.location.url);
    return thankYouPageUrl.toString().replace('orderIdPlaceholder', '{orderId}');
  }

  private async fetchContinueShoppingLink(): Promise<ILink> {
    if (this.siteStore.experiments.enabled(SPECS.HeadlessNavigationCheckout)) {
      const url = getHeadlessUrl({
        query: this.siteStore.location.query,
        baseUrl: this.siteStore.location.baseUrl,
        navParams: {logicalName: NavigationType.ECOM_CONTINUE_BROWSING},
      });

      if (url) {
        return this.getExternalLink(url);
      }
    }
    const continueShoppingUrl = this.appSectionParams.continueShoppingUrl;
    return continueShoppingUrl && isValidHttpUrl(continueShoppingUrl)
      ? this.getExternalLink(continueShoppingUrl)
      : this.siteStore.getHomepageLink();
  }

  private async fetchEditCartUrl(): Promise<string> {
    if (this.siteStore.experiments.enabled(SPECS.HeadlessNavigationCheckout)) {
      const url = getHeadlessUrl({
        query: this.siteStore.location.query,
        baseUrl: this.siteStore.location.baseUrl,
        navParams: {logicalName: NavigationType.ECOM_CART},
      });

      if (url) {
        return url;
      }
    }
    const {url} = await this.siteStore.getSectionUrl(PageMap.CART);
    return `${url!}?appSectionParams=${encodeURIComponent(JSON.stringify({origin: 'checkout'}))}`;
  }

  public navigateToContinueShopping(): void {
    return this.siteStore.navigateToLink(this.continueShoppingLink);
  }

  public navigateToThankYouPage(orderId: string): Promise<void> | void {
    return this.siteStore.navigate(
      {
        sectionId: PageMap.THANKYOU,
        queryParams: {
          objectType: 'order',
          origin: 'checkout',
          continueShoppingUrl: this.continueShoppingLink.url,
        },
        state: orderId,
      },
      true
    );
  }

  public navigateToCart(cartId?: string): Promise<void> {
    return this.siteStore.navigate(
      {
        sectionId: PageMap.CART,
        queryParams: {
          objectType: 'order',
          origin: 'checkout',
        },
        state: cartId,
      },
      true
    );
  }

  private getCurrency() {
    return this.siteStore.location.query.currency;
  }

  private getAppSectionParams(): CheckoutAppSectionParams {
    const appSectionParams = this.siteStore.location.query.appSectionParams;
    if (!appSectionParams) {
      return {};
    }
    try {
      return JSON.parse(appSectionParams) as CheckoutAppSectionParams;
    } catch {
      /* istanbul ignore next */
      return {};
    }
  }

  private getExternalLink(url: string): ILink {
    return {url, sdkLink: {type: 'ExternalLink', target: '_top', url}};
  }

  private getInternalLink(url: string): ILink {
    return {url, sdkLink: {type: 'ExternalLink', target: '_self', url}};
  }

  public get continueShoppingUrl() {
    return this.continueShoppingLink.url;
  }

  public get externalContinueShoppingUrl() {
    return this.appSectionParams.continueShoppingUrl;
  }

  public get isFastFlow() {
    return Boolean(this.appSectionParams?.cashierPaymentId);
  }

  public get isPickupFlow() {
    return Boolean(this.appSectionParams?.isPickupFlow);
  }

  public get origin() {
    return this.appSectionParams.origin;
  }

  public get originType() {
    return this.appSectionParams.originType;
  }

  public get editCartUrl() {
    return this.fetchedEditCartUrl;
  }

  public get checkoutId() {
    return this.appSectionParams.checkoutId;
  }

  public get cashierPaymentId() {
    return this.appSectionParams.cashierPaymentId;
  }
}
