import {StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';
import {ShowMandatoryTitleModel} from './ShowMandatoryTitle.model';
import {ValueEnabledModel} from './ValueEnabled.model';
import {ValueEnabledTitleModel} from './ValueEnabledTitle.model';
import {ShowMandatoryModel} from './ShowMandatory.model';

export class CheckoutSettingsModel {
  public storeName?: string;
  public logoUrl?: string;
  public taxName: string;
  public taxOnProduct: boolean;
  public customField: ShowMandatoryTitleModel;
  public addressLine2: ShowMandatoryModel;
  public companyName: ShowMandatoryModel;
  public phone: ShowMandatoryModel;
  public termsAndConditions: ValueEnabledModel;
  public privacyPolicy: ValueEnabledModel;
  public returnPolicy: ValueEnabledModel;
  public digitalPolicy: ValueEnabledModel;
  public contactUs: ValueEnabledTitleModel;
  public customPolicy: ValueEnabledTitleModel;
  public arePoliciesCheckedByDefault: boolean;
  public areGeneralPoliciesEnabled: boolean;
  public isThereAnyPolicyEnabled: boolean;
  public isDigitalPolicyEnabled: boolean;
  public isSubscriptionCheckedByDefault: boolean;
  public isSubscriptionEnabled: boolean;
  public isGiftCardEnabled: boolean;
  public isGiftCardSupported: boolean;

  constructor(checkoutSettings: StoreCheckoutSettings, taxName: string) {
    this.storeName = checkoutSettings.storeName ?? undefined;
    this.logoUrl = checkoutSettings.logo?.url;
    this.taxName = taxName;
    this.taxOnProduct = checkoutSettings.taxOnProduct ?? false;
    this.customField = new ShowMandatoryTitleModel(checkoutSettings.checkoutCustomField);
    this.addressLine2 = new ShowMandatoryModel(checkoutSettings.checkoutAddressLine2);
    this.companyName = new ShowMandatoryModel(checkoutSettings.checkoutCompanyName);
    this.phone = new ShowMandatoryModel(checkoutSettings.checkoutPhone);
    this.termsAndConditions = new ValueEnabledModel(checkoutSettings.termsAndConditions);
    this.privacyPolicy = new ValueEnabledModel(checkoutSettings.privacyPolicy);
    this.returnPolicy = new ValueEnabledModel(checkoutSettings.refundCancellationPolicy);
    this.digitalPolicy = new ValueEnabledModel(checkoutSettings.digitalItemPolicy);
    this.contactUs = new ValueEnabledTitleModel(checkoutSettings.contactUs);
    this.customPolicy = new ValueEnabledTitleModel(checkoutSettings.customPolicy);
    this.arePoliciesCheckedByDefault = !!checkoutSettings.checkoutPolicyCheckbox?.checked;
    this.isThereAnyPolicyEnabled =
      this.termsAndConditions.enabled || this.privacyPolicy.enabled || this.returnPolicy.enabled;
    this.areGeneralPoliciesEnabled = !!checkoutSettings.checkoutPolicyCheckbox?.show && this.isThereAnyPolicyEnabled;
    this.isDigitalPolicyEnabled = !!checkoutSettings.checkoutPolicyCheckbox?.show && this.digitalPolicy.enabled;
    this.isSubscriptionCheckedByDefault = !!checkoutSettings.checkoutSubscriptionCheckbox?.checked;
    this.isSubscriptionEnabled = !!checkoutSettings.checkoutSubscriptionCheckbox?.show;
    this.isGiftCardEnabled = !!checkoutSettings.checkoutGiftCardCheckbox?.show;
    this.isGiftCardSupported = !!checkoutSettings.checkoutGiftCardCheckbox?.supported;
  }
}
